import { navigate } from "gatsby"
import {
  Box,
  Button,
  Form,
  FormField,
  Grommet,
  Heading,
  TextInput,
} from "grommet"
import React from "react"
import theme from "../../components/calculator/theme"
import { postLead } from "../../services/furnace-marketing"

export default function Page() {
  const [value, setValue] = React.useState({})

  const onSubmit = async () => {
    const response = await postLead(value)

    navigate("/form/complete")
  }

  return (
    <Grommet theme={theme} full>
      <Box width="xlarge" margin="auto" justify="center" align="center">
        <Heading>Join Furnace Cloud Waitlist</Heading>
        <Form
          value={value}
          onChange={nextValue => setValue(nextValue)}
          onReset={() => setValue({})}
          onSubmit={onSubmit}
        >
          <FormField name="Name" htmlFor="Name" label="Name">
            <TextInput id="Name" name="Name" />
          </FormField>
          <FormField
            name="Company Name"
            htmlFor="Company Name"
            label="Company Name"
          >
            <TextInput id="Company Name" name="Company Name" />
          </FormField>
          <FormField name="Email" htmlFor="Email" label="Email">
            <TextInput id="Email" name="Email" />
          </FormField>
          <Box direction="row" gap="medium">
            <Button type="submit" primary label="Submit" />
          </Box>
        </Form>
      </Box>
    </Grommet>
  )
}
